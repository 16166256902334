import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudylifesync.scss';

import imgQuote from "../images/quotation_mark.webp";


export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />
        <div className="case-study-one-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img src={pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp.fluid.src} className="client-logo" width={pageContext.acf.client_logo.media_details.width/2} height={pageContext.acf.client_logo.media_details.height/2} alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2022/11/lifesync-ecommerce-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div className="first-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.first_paragraph}} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 order-2">
                        <div className="second-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.second_paragraph}} />
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <img src={pageContext.acf.desktop_image.csOneDesImaLocalFile.childImageSharp.fluid.src} className="desktop-image img-fluid" width={pageContext.acf.desktop_image.media_details.width} height={pageContext.acf.desktop_image.media_details.height} alt="" />
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <img src="https://www.meritmile.com/uploads/2021/02/lifesync-solutions-both.png" className="desktop-image img-fluid" width={pageContext.acf.desktop_image.media_details.width} height={pageContext.acf.desktop_image.media_details.height} alt=""  style={{ maxWidth: '80%'}} />
                        <p>&nbsp;</p>
                    </div>
                    <div className="col-md-6">
                        <div className="third-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.third_paragraph}} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 order-3">
                        <div className="fourth-paragraph" dangerouslySetInnerHTML={{ __html: pageContext.acf.fourth_paragraph}} />
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 order-2">
                        <div className="second-paragraph">
                          <h2><strong>Deliverables</strong></h2>
                          <ul>
                            <li>Brand identity and messaging</li>
                            <li>eCommerce design and development</li>
                            <li>Website visual design and development</li>
                            <li>Product photography and graphic design</li>
                            <li>Product management and optimization</li>
                            <li>Sales and marketing collateral</li>
                            <li>Demand generation campaigns</li>
                          </ul>
                        </div>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <img src="https://www.meritmile.com/uploads/2021/02/lifesync-deliverables.png" className="desktop-image img-fluid" width="542" alt=""/>
                        <p>&nbsp;</p>
                    </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h2><strong>Platforms</strong></h2>
                  </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="other-paragraphs">
                          <ul>
                            <li>BigCommerce</li>
                            <li>GatsbyJS</li>
                            <li>ShipperHQ</li>
                          </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="other-paragraphs">
                          <ul>
                            <li>Cart2Cart migration</li>
                            <li>Braintree</li>
                          </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>"For more insights and to connect with Merit Mile <a href="mailto:LifeSync@MeritMile.com?subject=Inquiry from LifeSync Case Study"><span style={{ fontWeight: 'bold', color: 'black'}}>email us here</span></a>."</p>
                    </div>
                </div>
            </div>            
        </div>
    </Layout>
)